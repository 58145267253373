import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Spinner } from "reactstrap";
import Header from "../Header";
import Streamer from "./Streamer";
import AddOrder from "./AddOrder";
import Login from "./Login";
import "../../css/App.css";

import * as alertsActions from "../../actions/alertsActions";
import * as dashboardActions from "../../actions/dashboardActions";

class App extends Component {
  state = {
    username: "",
    password: "",
    isAlertVisible: false,
    isSuccessAlertVisible: false,
    ordersToDelete: []
  };

  componentDidMount() {
    this.props.checkLogin();
  }

  handleChange = event => {
    this.setState({ [event.currentTarget.name]: event.target.value });
  };

  onSuccessAlertToggle = () => {
    this.setState({ isSuccessAlertVisible: !this.state.isSuccessAlertVisible });
  };

  onAlertToggle = () => {
    this.setState({ isAlertVisible: !this.state.isAlertVisible });
  };

  onShowAlert = () => {
    window.setTimeout(() => {
      if (this.state.isSuccessAlertVisible) {
        this.onSuccessAlertToggle();
      }
    }, 1500);
  };

  render() {
    let { isLoggedIn, username, loading } = this.props;
    let loggedInClass = "App";
    if (!isLoggedIn && !loading) {
      loggedInClass = "App not_logged_in";
    }
    return (
      <div className={loggedInClass}>
        {isLoggedIn ? <Header username={username} /> : ""}
        <div className="container-fluid">
          <img
            src={require("../../css/background.jpg")}
            alt="background"
            style={{ display: "None" }}
            onLoad={this.handleBackgroundLoad}
          />
          <div className="row align-items-center justify-content-center">
            <Alert
              id="alert-container"
              color="success"
              isOpen={this.props.success}
              toggle={e => this.props.toggleSuccess()}
            >
              {this.props.message}
            </Alert>
          </div>
          <div className="row align-items-center justify-content-center">
            <Alert
              id="alert-container"
              color="info"
              isOpen={this.props.working}
              toggle={e => this.props.toggleWorking()}
            >
              {this.props.message}
            </Alert>
          </div>
          <div className="row align-items-center justify-content-center">
            <Alert
              id="alert-container"
              color="danger"
              isOpen={this.props.errorAlert}
              toggle={e => this.props.toggleErrorAlert()}
            >
              {this.props.message}
            </Alert>
          </div>
          <div className="row">
            {loading ? (
              <div className="row mx-auto">
                <div className="col">
                  <Spinner
                    style={{ width: "5rem", height: "5rem", marginTop: "50px" }}
                    color="primary"
                  />
                </div>
              </div>
            ) : (
              <div className="col">
                {isLoggedIn ? (
                  <React.Fragment>
                    <div className="row">
                      <div className="col">
                        <AddOrder />
                      </div>
                    </div>
                    <div className="row">
                      <Streamer />
                    </div>
                  </React.Fragment>
                ) : (
                  <Login />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.dashboardReducer, ...state.alertsReducer };
};
const mapDispatchToProps = {
  ...dashboardActions,
  ...alertsActions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
