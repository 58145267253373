import {
  LOGGED_IN,
  LOGGED_OUT,
  LOADING,
  CHANGE_USERNAME,
  CHANGE_PASSWORD,
  CHANGE_TOKEN,
  ERROR
} from "../types/dashboardTypes";

export const changeUsername = username => async dispatch => {
  dispatch({
    type: CHANGE_USERNAME,
    payload: username
  });
};

export const changePassword = password => async dispatch => {
  dispatch({
    type: CHANGE_PASSWORD,
    payload: password
  });
};

export const loggedIn = () => async dispatch => {
  dispatch({
    type: LOGGED_IN
  });
};

export const checkLogin = () => async dispatch => {
  dispatch({
    type: LOADING
  });
  const localStorageRef = localStorage.getItem("access_token");
  const localStorageAccountNumber = localStorage.getItem("account_number");
  const localStorageUsername = localStorage.getItem("username");
  if (localStorageRef) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/protected/`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorageRef,
            "Content-Type": "application/json"
          }
        }
      );
      let request = await response.json();
      let data = JSON.parse(request);
      if (data.msg) {
        localStorage.removeItem("access_token");
        if (localStorageAccountNumber) {
          localStorage.removeItem("account_number");
        }
        dispatch({
          type: LOGGED_OUT
        });
      } else {
        if (localStorageUsername) {
          dispatch({
            type: CHANGE_USERNAME,
            payload: localStorageUsername
          });
        }
        dispatch({
          type: CHANGE_TOKEN,
          payload: localStorageRef
        });
        dispatch({
          type: LOGGED_IN
        });
      }
    } catch (error) {
      localStorage.removeItem("access_token");
      if (localStorageAccountNumber) {
        localStorage.removeItem("account_number");
      }
      dispatch({
        type: LOGGED_OUT
      });
      console.log(error.message);
      dispatch({
        type: ERROR,
        payload: error.message
      });
    }
  } else {
    dispatch({
      type: LOGGED_OUT
    });
  }
};
