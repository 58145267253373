import React from "react";
import { Link } from "react-router-dom";

const handleLogout = event => {
  const localStorageAccountNumber = localStorage.getItem("account_number");
  localStorage.removeItem("access_token");
  if (localStorageAccountNumber) {
    localStorage.removeItem("account_number");
  }
  window.location.reload();
};

const Header = props => (
  <div className="header">
    <Link to="/" className="nav-link active">
      QTracks
    </Link>

    <Link to="/placed" className="nav-link">
      {props.username || ""}
    </Link>

    <Link to="/" className="nav-link" onClick={handleLogout}>
      Log-out
    </Link>

    <Link to="/" className="nav-link">
      Support: (323) 472-3400
    </Link>
  </div>
);
export default Header;
