import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spinner, Card, CardBody } from "reactstrap";
import * as userOrdersActions from "../../actions/userOrdersActions";
import moment from "moment";
import "../../css/App.css";

class PlacedOrders extends Component {
  componentDidMount() {
    this.props.getPlacedOrders();
  }

  deleteOrder = orderId => {
    this.props.deleteOrder(orderId);
    this.props.getPlacedOrders();
  };

  orders = () => {
    return this.props.response.map((order, index) => {
      return (
        <Card
          key={`order${index}`}
          style={{
            marginBottom: "30px",
            backgroundColor: "#f8f8f8",
            border: "0"
          }}
        >
          <ul
            key={order.orderId}
            style={{ paddingTop: "20px", listStyle: "none" }}
          >
            <li>
              Session: {order.session} | Duration: {order.duration} | Order
              Type: {order.orderType} | Price: {order.price} | Quantity:{" "}
              {order.quantity}
            </li>
            <ul>
              {order.orderLegCollection.map(leg => {
                return (
                  <React.Fragment key={leg.legId}>
                    <li>
                      {leg.instrument.symbol} | {leg.instruction} | Qty:{" "}
                      {leg.quantity}
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
            <li>Entered Time: {moment(order.enteredTime).calendar()}</li>
            <li>
              <Button
                color="danger"
                onClick={e => this.deleteOrder(order.orderId)}
              >
                Delete
              </Button>{" "}
              <Button color="warning">{order.status} </Button>
            </li>
          </ul>
        </Card>
      );
    });
  };
  render() {
    return (
      <div className="placed_orders" style={{ marginTop: "80px" }}>
        {!this.props.response.length ? (
          <Spinner
            style={{
              width: "5rem",
              height: "5rem",
              marginTop: "50px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block"
            }}
            color="primary"
          />
        ) : (
          <Card style={{ padding: "40px" }}>{this.orders()}</Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ userOrdersReducer }) => userOrdersReducer;

export default connect(
  mapStateToProps,
  userOrdersActions
)(PlacedOrders);
