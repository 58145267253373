import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { Spring } from "react-spring/renderprops";
import * as ordersActions from "../../actions/ordersActions";
import Order from "./Order";
import "../../css/Order.css";
import openSocket from "socket.io-client";

const socket = openSocket(`${process.env.REACT_APP_STREAMER_API}`, {
  transports: ["websocket"],
  upgrade: false
});
class Streamer extends Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    const localStorageAccountNumber = localStorage.getItem("account_number");
    if (localStorageAccountNumber) {
      socket.emit("enterRoom", localStorageAccountNumber);
      socket.emit("orderReply", localStorageAccountNumber);

      socket.on("orderReply", data => {
        if (data && this.state.isLoading === true) {
          this.setState({ isLoading: false });
        }
        if (data) {
          let orders = JSON.parse(data).map((order, id) => ({ ...order, id }));
          this.props.updateOrders(orders);
        }
      });
      socket.on("disconnect", data => {
        alert("Stream disconnected due to inactivity, refreshing page...");
        window.location.reload();
      });
    }
  }
  componentWillUnmount() {
    socket.off();
  }

  handleCheckbox = () => {
    if (this.props.allSelected) {
      this.props.cleanToDelete();
    } else {
      this.props.selectOrders(Object.keys(this.props.orders));
    }
  };

  changeAllSelected = () => {
    this.props.changeAllSelected(!this.props.allSelected);
  };

  render() {
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <div className="Streamer mx-auto">
            <Spinner
              style={{ width: "5rem", height: "5rem" }}
              color="primary"
            />
          </div>
        ) : (
          <div className="col">
            <table style={{ width: "100%" }}>
              <tbody>
                <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                  {props => (
                    <tr style={props}>
                      <th
                        className="table__head"
                        style={{ borderLeft: "0px", borderTop: "0px" }}
                      />
                      <th className="table__head" colSpan="5">
                        Order Details
                      </th>
                      <th className="table__head" colSpan="2">
                        Market Price and Size
                      </th>
                      <th className="table__head" colSpan="2">
                        Order Status
                      </th>
                      <th className="table__head" colSpan="3">
                        Risk Management
                      </th>
                    </tr>
                  )}
                </Spring>
                <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                  {props => (
                    <tr style={props}>
                      <td className="table__header">
                        <input
                          type="checkbox"
                          checked={this.props.allSelected}
                          onChange={this.changeAllSelected}
                          onClick={this.handleCheckbox}
                        />
                      </td>
                      <td className="table__header order_details">Action</td>
                      <td className="table__header order_details">Symbol</td>
                      <td className="table__header order_details">Order</td>
                      <td className="table__header order_details">Size</td>
                      <td className="table__header order_details">Price</td>
                      <td className="table__header market">Bid</td>
                      <td className="table__header market">Ask</td>
                      <td className="table__header status">Pricing</td>
                      <td className="table__header status">Status</td>
                      <td className="table__header risk">Bid Ask IV</td>
                      <td className="table__header risk">Delta</td>
                      <td className="table__header risk">Theta</td>
                    </tr>
                  )}
                </Spring>

                <Order />
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ ordersReducer }) => ordersReducer;

export default connect(
  mapStateToProps,
  ordersActions
)(Streamer);
