import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { useTransition, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_LEGS } from "../../types/legsTypes";
import "../../css/AddLeg.css";

const AddLeg = props => {
  const legs = useSelector(state => state.legsReducer.legs);
  const dispatch = useDispatch();

  const transitions = useTransition(legs, leg => leg.id, {
    from: { height: 0, opacity: 0 },
    enter: { height: 41, opacity: 1 },
    leave: { height: 0, opacity: 0 }
  });

  const deleteLeg = index => {
    const deleteItem = () => {
      let newArray = legs.slice();
      newArray.splice(index, 1);
      return newArray;
    };

    const updated = deleteItem();

    dispatch({
      type: UPDATE_LEGS,
      payload: updated
    });
  };

  const createLeg = () => {
    const insertItem = (legs, updated) => {
      let newArray = legs.slice();
      newArray.splice(legs.length, 0, updated);
      return newArray;
    };

    const newLeg = {
      orderType: "OPTION",
      symbol: "",
      instruction: "BUY_TO_OPEN",
      quantity: "1",
      ticker: "",
      expiration: "",
      strike: "",
      putcall: "CALL",
      isNotValid: false,
      detailedSymbol: false,
      id: Date.now()
    };
    const updated = insertItem(legs, newLeg);

    dispatch({
      type: UPDATE_LEGS,
      payload: updated
    });
  };

  const changeForm = (event, item) => {
    const index = Object.keys(legs).find(key => legs[key].id === item);
    const updated = [...legs];
    updated[index] = {
      ...legs[index]
    };
    updated[index][event.currentTarget.name] = event.target.value;
    dispatch({
      type: UPDATE_LEGS,
      payload: updated
    });
  };

  const addLegs = () => {
    let { detailedSymbols } = props;
    const detailedSymbolForm = item => (
      <React.Fragment>
        <div className="legs__col">
          <Input
            type="text"
            name="ticker"
            id="ticker"
            bsSize="sm"
            placeholder=""
            value={item.ticker}
            onChange={e => changeForm(e, item.id)}
          />
        </div>
        <div className="legs__col">
          <Input
            type="date"
            name="expiration"
            id="expiration"
            bsSize="sm"
            placeholder=""
            value={item.expiration}
            onChange={e => changeForm(e, item.id)}
          />
        </div>
        <div className="legs__col">
          <Input
            type="text"
            name="strike"
            id="strike"
            bsSize="sm"
            placeholder=""
            value={item.strike}
            onChange={e => changeForm(e, item.id)}
          />
        </div>
        <div className="legs__col">
          <Input
            type="select"
            name="putcall"
            id="putcall"
            bsSize="sm"
            value={item.putcall}
            onChange={e => changeForm(e, item.id)}
          >
            <option>PUT</option>
            <option>CALL</option>
          </Input>
        </div>
      </React.Fragment>
    );

    const complexSymbolForm = item => (
      <div className="add_new_leg legs__col">
        <Input
          type="text"
          name="symbol"
          id="symbol"
          bsSize="sm"
          placeholder="Example: TSLA_011521C600"
          value={item.symbol}
          onChange={e => changeForm(e, item.id)}
          invalid={item.isNotValid}
        />
      </div>
    );

    let automaticLegTag = legs.length > 1 ? "Multileg" : "Normal";

    return transitions.map(({ item, props, key }, index) => (
      <animated.div
        className={
          detailedSymbols ? "legs__container_full" : "legs__container_short"
        }
        key={key}
        style={props}
      >
        <div className="legs__col">{index === 0 ? automaticLegTag : ""}</div>
        <div className="leg legs__col">
          <Input
            type="select"
            name="orderType"
            id="orderType"
            bsSize="sm"
            value={item.orderType}
            onChange={e => changeForm(e, item.id)}
          >
            <option>EQUITY</option>
            <option>OPTION</option>
          </Input>
        </div>
        {detailedSymbols ? detailedSymbolForm(item) : complexSymbolForm(item)}

        <div className="legs__col">
          <Input
            type="select"
            name="instruction"
            id="instruction"
            bsSize="sm"
            value={item.instruction}
            onChange={e => changeForm(e, item.id)}
          >
            <option>BUY</option>
            <option>SELL</option>
            <option>BUY_TO_COVER</option>
            <option>SELL_SHORT</option>
            <option>BUY_TO_OPEN</option>
            <option>BUY_TO_CLOSE</option>
            <option>SELL_TO_OPEN</option>
            <option>SELL_TO_CLOSE</option>
          </Input>
        </div>
        <div className="legs__col">
          <Input
            type="number"
            step="1"
            min="1"
            name="quantity"
            id="quantity"
            bsSize="sm"
            placeholder="Enter the quantity"
            value={item.quantity}
            onChange={e => changeForm(e, item.id)}
          />
        </div>

        {index === 0 ? (
          <div
            className="legs__col"
            onClick={() =>
              item.symbol !== "" || item.ticker !== ""
                ? createLeg()
                : alert("Please fill all the fields.")
            }
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{ cursor: "pointer" }}
            />
          </div>
        ) : (
          <div className="legs__col" onClick={e => deleteLeg(index)}>
            <FontAwesomeIcon
              icon={faMinusCircle}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </animated.div>
    ));
  };

  return <React.Fragment>{addLegs()}</React.Fragment>;
};

export default AddLeg;
