import React, { Component } from "react";
import { Button, FormGroup, Label, Input, Form } from "reactstrap";

class Register extends Component {
  state = {
    username: "",
    password: "",
    passwordCheck: "",
    token_key: ""
  };

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get("code");
    this.setState({ token_key: foo });
  }

  handleChange = event => {
    this.setState({ [event.currentTarget.name]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (
      (this.state.username !== "") &
      (this.state.password !== "") &
      (this.state.passwordCheck !== "")
    ) {
      if (this.state.password === this.state.passwordCheck) {
        fetch(`${process.env.REACT_APP_API_URL}/confirm/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: this.state.username,
            password: this.state.password,
            token_key: this.state.token_key
          })
        })
          .then(res => res.json())
          .then(data => {
            let response = JSON.parse(data);
            console.log(response["operation"]);
            if (response) {
              if (response["operation"]) {
                if (response["operation"] === "success") {
                  alert("Account created successfully, going back...");
                  this.props.history.push("/");
                } else {
                  alert(
                    "The account provided didn't work, the username is taken or you already have an account with us, please try again later. If the problem persists, try calling for support"
                  );
                }
              }
            }
          })
          .catch(error => console.error("Error", error));
      } else {
        alert("Both passwords don't match.");
      }
    } else {
      alert("Please fill username and set a password");
    }
  };

  render() {
    return (
      <div className="Register">
        <div className="container">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className="nav-link" href={process.env.REACT_APP_HOME_URL}>
                QTracks
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                href="https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=https%3A%2F%2Fqtrack.rivka.mx%2Fregister&client_id=A12B312C4335%40AMER.OAUTHAP"
                target="_blank"
                rel="noopener noreferrer"
              >
                New account
              </a>
            </li>
            <li className="nav-item">
              <span className="nav-link">Support: (323) 472-3400</span>
            </li>
          </ul>
          <Form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col">
                <h3>Register</h3>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label for="username">Username:</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Set a username"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label for="password">Set password:</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter a password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password again:</Label>
                  <Input
                    type="password"
                    name="passwordCheck"
                    id="passwordCheck"
                    placeholder="Enter the password again"
                    value={this.state.passwordCheck}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Button>Submit</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Register;
