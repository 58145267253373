import { UPDATE_LEGS, ERROR } from "../types/legsTypes";

const INITIAL_STATE = {
  legs: [
    {
      orderType: "OPTION",
      symbol: "",
      instruction: "BUY_TO_OPEN",
      quantity: "1",
      ticker: "",
      expiration: "",
      strike: "",
      putcall: "CALL",
      isNotValid: false,
      id: 0
    }
  ],
  error: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LEGS:
      return { ...state, legs: action.payload };
    case ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
