import {
  CHANGE_PASSWORD,
  CHANGE_TOKEN,
  CHANGE_USERNAME,
  ERROR,
  LOADING,
  LOGGED_IN,
  LOGGED_OUT
} from "../types/dashboardTypes";

const INITIAL_STATE = {
  isLoggedIn: false,
  loading: false,
  username: "",
  password: "",
  token: "",
  error: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { ...state, password: action.payload };
    case CHANGE_TOKEN:
      return { ...state, token: action.payload };
    case CHANGE_USERNAME:
      return { ...state, username: action.payload };
    case ERROR:
      return { ...state, error: action.payload, loading: false };
    case LOADING:
      return { ...state, loading: true };
    case LOGGED_IN:
      return { ...state, isLoggedIn: true, loading: false };
    case LOGGED_OUT:
      return { ...state, isLoggedIn: false, loading: false };
    default:
      return state;
  }
};
