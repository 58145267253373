import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheckCircle,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { CHANGE_DELETE } from "../../types/ordersTypes";
import {
  CHANGE_WORKING_ALERT,
  CHANGE_SUCCESS_ALERT,
  CHANGE_ERROR_ALERT,
  CHANGE_MESSAGE_ALERT,
  CLEAN_ALERTS
} from "../../types/alertsTypes";

const formatOrder = order => {
  let formattedOrder = {
    accountId: order["accountId"],
    session: order["session"],
    duration: order["duration"],
    orderType: order["orderType"],
    legs: order["legs"].map(leg => {
      let formattedLeg = {
        orderLegType: leg["orderLegType"],
        assetType: leg["assetType"],
        symbol: leg["symbol"],
        instruction: leg["instruction"],
        quantity: leg["quantity"]
      };
      return formattedLeg;
    }),
    orderStrategyType: order["orderStrategyType"],
    price: order["price"].toString()
  };
  return formattedOrder;
};

const Order = props => {
  const orders = useSelector(state => state.ordersReducer.orders);
  const toDelete = useSelector(state => state.ordersReducer.toDelete);
  const dispatch = useDispatch();

  const deleteOrder = async orderIndex => {
    // send a request to delete an order
    dispatch({
      type: CHANGE_WORKING_ALERT,
      payload: true
    });
    dispatch({
      type: CHANGE_MESSAGE_ALERT,
      payload: "Deleting order"
    });
    const token = localStorage.getItem("access_token");
    let order = formatOrder(orders[orderIndex]);
    delete order.orderType;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteorder/`,
        {
          method: "delete",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(order)
        }
      );
      const data = await response.json();
      console.log(data);
      dispatch({
        type: CHANGE_SUCCESS_ALERT,
        payload: true
      });
      dispatch({
        type: CHANGE_MESSAGE_ALERT,
        payload: "Order deleted successfully"
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_ERROR_ALERT,
        payload: true
      });
      dispatch({
        type: CHANGE_MESSAGE_ALERT,
        payload: error
      });
    }

    window.setTimeout(() => {
      dispatch({
        type: CLEAN_ALERTS
      });
    }, 2000);
  };

  const stageOrder = async orderIndex => {
    // send a request to change the order stage
    const token = localStorage.getItem("access_token");
    let order = formatOrder(orders[orderIndex]);
    delete order.orderType;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/stageorder/`,
      {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(order)
      }
    );
    const data = await response.json();
    console.log(data);
  };

  const insertItem = (arr, index) => {
    let newArray = arr.slice();
    newArray.splice(arr.length, 0, index);
    return newArray;
  };
  const deleteItem = (arr, index) => {
    let newArray = arr.slice();
    newArray.splice(index, 1);
    return newArray;
  };

  const addOrderToDelete = orderIndex => {
    const toDeleteIndex = toDelete.indexOf(orderIndex);

    if (toDeleteIndex >= 0) {
      // Order is in toDelete, get it out of there.
      const updated = deleteItem(toDelete, toDeleteIndex);
      dispatch({
        type: CHANGE_DELETE,
        payload: updated
      });
    } else {
      // Order is not in toDelete so add it.
      const updated = insertItem(toDelete, orderIndex);
      dispatch({
        type: CHANGE_DELETE,
        payload: updated
      });
    }
  };

  const getOrderStatus = (orderIndex, legIndex) => {
    let operation = orders[orderIndex].legs[legIndex].operation;
    let status = orders[orderIndex].status;
    let brokerStatus = orders[orderIndex].brokerStatus;
    if (operation !== "success") {
      if (!operation) {
        return "CALCULATING";
      }
    }
    if (status === "STAGING" && operation === "success") {
      return "READY";
    } else if (status === "STAGED" && typeof brokerStatus !== "undefined") {
      if (brokerStatus === "CANCELED" || brokerStatus === "ERROR") {
        return "RETRY";
      }
      return brokerStatus;
    } else {
      return "SENT";
    }
    return "";
  };

  const getPricing = (isBid, price, marketBid, marketAsk) => {
    if (isBid) {
      let marketDiff = Math.round((price - marketBid) * 100) / 100;
      if (price - marketBid > 0) {
        return `Above Market ${marketDiff}`;
      } else if (price - marketBid < 0) {
        return `Below Market ${marketDiff}`;
      } else {
        return `At Market ${marketDiff}`;
      }
    } else {
      let marketDiff = Math.round((price - marketAsk) * 100) / 100;
      if (price - marketAsk > 0) {
        return `Above Market ${marketDiff}`;
      } else if (price - marketAsk < 0) {
        return `Below Market ${marketDiff}`;
      } else {
        return `At Market ${marketDiff}`;
      }
    }
  };

  const addLegs = (orderIndex, legIndex) => {
    let id = "";
    let pricing = "";
    let isBid = false;
    let order = orders[orderIndex];
    let leg = orders[orderIndex].legs[legIndex];
    let orderStrategyType = order["orderStrategyType"];
    let orderStatus = order.status;
    let orderMessage = order.message;
    let legsSize = order.legs.length;
    let marketBid = leg.marketBid;
    let marketAsk = leg.marketAsk;
    let bid = leg.bid;
    let ask = leg.ask;
    let ivAtBid = leg.ivAtBid;
    let ivAtAsk = leg.ivAtAsk;
    let operation = leg.operation;
    let legPrice = leg.price;
    let orderPrice = order.price;
    let orderCalculatedPrice = order.calculatedPrice;
    let orderLegType = leg.orderLegType;
    let symbol = leg.symbol;
    let instruction = leg.instruction;
    let quantity = leg.quantity;
    let bidSize = leg.bidSize;
    let askSize = leg.askSize;
    let delta = leg.delta;
    let theta = leg.theta;
    let totalDelta = order.totalDelta;
    let totalTheta = order.totalTheta;
    let triggerLeg = [];
    if (order["customStrategy"] !== "NORMAL")
      triggerLeg = order["childOrderStrategies"][0]["orderLegCollection"][0];
    if (legIndex) id = parseInt(legIndex) + 1;
    if (marketBid) marketBid = Math.round(marketBid * 100) / 100;
    if (marketAsk) marketAsk = Math.round(marketAsk * 100) / 100;
    if (bid) bid = Math.round(bid * 100) / 100;
    if (ask) ask = Math.round(ask * 100) / 100;
    if (ivAtBid) ivAtBid = Math.round(ivAtBid * 100) / 100;
    if (ivAtAsk) ivAtAsk = Math.round(ivAtAsk * 100) / 100;
    if (bid === legPrice) isBid = true;
    if (operation) pricing = getPricing(isBid, legPrice, marketBid, marketAsk);
    if (instruction)
      instruction = instruction.replace(new RegExp("_", "g"), " ");
    if (totalDelta) totalDelta = Math.round(totalDelta * 1000) / 1000;
    if (totalTheta) totalTheta = Math.round(totalTheta * 1000) / 1000;

    return (
      <React.Fragment key={`${orderIndex} ${legIndex}`}>
        <tr style={orderIndex % 2 === 0 ? { background: "#f8f8f8" } : {}}>
          {/* Checkbox */}
          <td>
            {id === 1 ? (
              <input
                type="checkbox"
                checked={toDelete.indexOf(orderIndex) >= 0}
                onChange={e => addOrderToDelete(orderIndex)}
              />
            ) : (
              ""
            )}
          </td>

          <td>
            {orderStatus === "STAGING" &&
            id === 1 &&
            orderMessage !== "WARNING" ? (
              <span
                className="clickable"
                onClick={() => stageOrder(orderIndex)}
              >
                Send
              </span>
            ) : (
              ""
            )}
            {id === 1 &&
            orderStatus === "STAGING" &&
            orderMessage === "WARNING" ? (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ margin: "0px 5px" }}
              />
            ) : (
              ""
            )}
            {id === 1 ? (
              <FontAwesomeIcon
                icon={faTrash}
                className="clickable"
                onClick={() => (id === 1 ? deleteOrder(orderIndex) : "")}
              />
            ) : (
              ""
            )}
          </td>
          <td>{orderLegType === "EQUITY" ? symbol.split("_")[0] : symbol}</td>
          <td>{instruction}</td>
          <td>{quantity}</td>
          <td>{legPrice}</td>
          <td>
            {operation === "success"
              ? orderLegType === "EQUITY"
                ? ""
                : `${marketBid} x ${bidSize}`
              : ""}
          </td>
          <td>
            {operation === "success"
              ? orderLegType === "EQUITY"
                ? ""
                : `${marketAsk} x ${askSize}`
              : ""}
          </td>
          <td>{orderLegType === "EQUITY" ? "" : pricing}</td>
          <td>{legIndex > 0 ? "" : getOrderStatus(orderIndex, legIndex)}</td>
          <td>
            {operation === "success"
              ? orderLegType === "EQUITY"
                ? ""
                : `${ivAtBid} x ${ivAtAsk}`
              : ""}
          </td>
          <td>{delta}</td>
          <td style={id === 1 && legsSize === 1 ? {} : {}}>{theta}</td>
        </tr>
        {id === legsSize ? (
          <React.Fragment>
            {order["customStrategy"] !== "NORMAL" ? (
              <tr style={orderIndex % 2 === 0 ? { background: "#f8f8f8" } : {}}>
                <td />
                <td>Trigger:</td>
                <td>{triggerLeg["instrument"]["symbol"]}</td>
                <td>{triggerLeg["instruction"]}</td>
                <td>{triggerLeg["quantity"]}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>
                  {order["childOrderStrategies"][0]["orderType"]
                    .split("_")
                    .join(" ")}
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <tr style={orderIndex % 2 === 0 ? { background: "#f8f8f8" } : {}}>
              <td />
              <td />
              <td>{orders[orderIndex].orderType}:</td>
              <td />
              <td />
              <td style={{ borderTop: "2px solid" }}>
                {orderStatus === "STAGING" ? orderCalculatedPrice : orderPrice}
              </td>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td style={{ borderTop: "2px solid" }}>{totalDelta}</td>
              <td style={{ borderTop: "2px solid" }}>{totalTheta}</td>
            </tr>
          </React.Fragment>
        ) : (
          <tr />
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {Object.keys(orders).map(orderIndex => (
        <React.Fragment key={orderIndex}>
          {Object.keys(orders[orderIndex].legs).map(legIndex =>
            addLegs(orderIndex, legIndex)
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default Order;
