export const getPlacedOrders = () => async dispatch => {
  const localStorageRef = localStorage.getItem("access_token");
  if (localStorageRef) {
    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/placed/`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorageRef,
          "Content-Type": "application/json"
        }
      });
      let data = await response.json();
      dispatch({
        type: "user_orders_change_response",
        payload: JSON.parse(data)
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  } else {
    console.log("Please login");
  }
};

export const deleteOrder = orderId => async dispatch => {
  const localStorageRef = localStorage.getItem("access_token");
  dispatch({
    type: "user_orders_change_response",
    payload: []
  });
  if (localStorageRef) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteplaced/`,
        {
          method: "delete",
          headers: {
            Authorization: "Bearer " + localStorageRef,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ orderId })
        }
      );
      let data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  } else {
    console.log("Please login");
  }
};
