export const CHANGE_IV_ADJUSTMENT = "orders_change_iv_adjustment";
export const CHANGE_PRICE_ADJUSTMENT = "orders_change_price_adjustment";
export const CHANGE_TIME_TO_EXPIRY = "orders_change_time_to_expiry";
export const CHANGE_CUSTOM_STRATEGY = "orders_change_custom_strategy";
export const CHANGE_PRICING_MODEL = "orders_change_pricing_model";
export const CHANGE_DELTA_ORDER_TYPE = "orders_change_delta_order_type";
export const CHANGE_IS_NOT_VALID = "orders_change_is_not_valid";
export const CHANGE_DELETE = "orders_change_delete";
export const CHANGE_ORDERS = "orders_change_orders";
export const CHANGE_ALL_SELECTED = "orders_change_all_selected";
export const ERROR = "orders_error";
export const LOADING = "orders_loading";
