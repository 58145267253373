import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import AddLeg from "./AddLeg";

import * as ordersActions from "../../actions/ordersActions";
import * as alertsActions from "../../actions/alertsActions";

class AddOrder extends Component {
  state = {
    detailedSymbols: false
  };
  changeIvAdjustment = event => {
    this.props.changeIvAdjustment(event.target.value);
  };

  changePriceAdjustment = event => {
    this.props.changePriceAdjustment(event.target.value);
  };

  changePricingModel = event => {
    console.log(event.target.value);
    this.props.changePricingModel(event.target.value);
  };

  changeCustomStrategy = event => {
    console.log(event.target.value);
    this.props.changeCustomStrategy(event.target.value);
  };

  changeDeltaOrderType = event => {
    console.log(event.target.value);
    this.props.changeDeltaOrderType(event.target.value);
  };

  changeToDetailedSymbols = () => {
    this.setState({ detailedSymbols: !this.state.detailedSymbols });
  };

  sendOrdersToDelete = () => {
    this.props.toggleWorking(true, "Deleting orders...");
    this.props.deleteOrders();
    this.props.toggleSuccess(true, "Orders deleted successfully");
    window.setTimeout(() => {
      this.props.cleanAlerts();
    }, 2000);
  };

  setColumnTitles = () => {
    const moreTitles = (
      <React.Fragment>
        <div className="legs__col">Expiration</div>
        <div className="legs__col">Strike</div>
        <div className="legs__col">Put/Call</div>
      </React.Fragment>
    );
    return (
      <div
        className={
          this.state.detailedSymbols
            ? "legs__container_full"
            : "legs__container_short"
        }
      >
        <div className="legs__col">Order</div>
        <div className="legs__col">Order Type</div>
        <div
          onClick={() => {
            this.changeToDetailedSymbols();
          }}
          className="symbol-ticker legs__col"
        >
          {this.state.detailedSymbols ? "Ticker" : "Symbol"}
        </div>
        {this.state.detailedSymbols ? moreTitles : <React.Fragment />}
        <div className="legs__col">Instruction</div>
        <div className="legs__col">Size</div>
        <div />
      </div>
    );
  };

  render() {
    return (
      <div className="AddOrder">
        <div className="row">
          <div className="col-12 col-sm-9">
            <div className="row">
              <div className="col border-bottom">
                <h3 className="text-center">Order Entry</h3>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className={"legs__container_of_container"}>
                  {this.setColumnTitles()}
                  <AddLeg detailedSymbols={this.state.detailedSymbols} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-3">
            <div className="row">
              <div className="col border-bottom">
                <h3 className="text-center">Pricing Engine</h3>
              </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col pt-1">
                <h6 className="font-weight-normal">Pricing Model:</h6>
              </div>
              <div className="col pt-1">
                <Input
                  type="select"
                  name="strategy"
                  id="strategy"
                  bsSize="sm"
                  value={this.props.pricingModel}
                  onChange={this.changePricingModel}
                >
                  <option>CONSERVATIVE</option>
                  <option>AGGRESSIVE</option>
                </Input>
              </div>
            </div>
            <div className="row">
              <div className="col pt-1">
                <h6 className="font-weight-normal">Strategy</h6>
              </div>
              <div className="col pt-1">
                <Input
                  type="select"
                  name="strategy"
                  id="strategy"
                  bsSize="sm"
                  value={this.props.customStrategy}
                  onChange={this.changeCustomStrategy}
                >
                  <option>NORMAL</option>
                  <option>DELTA NEUTRAL</option>
                </Input>
              </div>
            </div>
            {this.props.customStrategy === "DELTA NEUTRAL" ? (
              <div className="row">
                <div className="col pt-1">
                  <h6 className="font-weight-normal">Delta Order</h6>
                </div>
                <div className="col pt-1">
                  <Input
                    type="select"
                    name="deltaOrderType"
                    id="deltaOrderType"
                    bsSize="sm"
                    value={this.props.deltaOrderType}
                    onChange={this.changeDeltaOrderType}
                  >
                    <option>MARKET</option>
                    <option>TRAILING STOP LIMIT</option>
                  </Input>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col pt-1">
                <h6 className="font-weight-normal">IV Adjustment</h6>
              </div>
              <div className="col pt-1">
                <Input
                  type="number"
                  step="0.01"
                  name="ivAdjustment"
                  id="ivAdjustment"
                  bsSize="sm"
                  placeholder="Enter the amount to adjust"
                  value={this.props.ivAdjustment}
                  onChange={this.changeIvAdjustment}
                />
              </div>
            </div>
            <div className="row">
              <div className="col pt-1">
                <h6 className="font-weight-normal">Price Adjustment</h6>
              </div>
              <div className="col pt-1">
                <Input
                  type="number"
                  step="0.01"
                  name="priceAdjustment"
                  id="priceAdjustment"
                  bsSize="sm"
                  placeholder="Enter the price amount to adjust"
                  value={this.props.priceAdjustment}
                  onChange={this.changePriceAdjustment}
                />
              </div>
            </div>
            <div
              className="row"
              style={{ marginBottom: "20px", marginTop: "20px" }}
            >
              <div className="col">
                <Button
                  onClick={e =>
                    this.props.sendOrder(this.state.detailedSymbols)
                  }
                  color="success"
                  block
                >
                  Calculate Order
                </Button>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col">
                <Button color="danger" onClick={this.sendOrdersToDelete} block>
                  Delete selected
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ordersReducer }) => ordersReducer;
const mapDispatchToProps = {
  ...ordersActions,
  ...alertsActions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrder);
