import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody
} from "reactstrap";

import * as dashboardActions from "../../actions/dashboardActions";

class Login extends Component {
  changeUsername = event => {
    this.props.changeUsername(event.target.value);
  };

  changePassword = event => {
    this.props.changePassword(event.target.value);
  };

  handleSubmit = event => {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: this.props.username,
        password: this.props.password
      })
    })
      .then(res => res.json())
      .then(data => {
        let response = JSON.parse(data);
        if (response["access_token"]) {
          localStorage.setItem("access_token", response["access_token"]);
          localStorage.setItem("account_number", response["account_number"]);
          localStorage.setItem("username", this.props.username);

          this.props.loggedIn();
        } else {
          console.log(JSON.stringify(response));
          alert("Bad username or password");
        }
      })
      .catch(error => console.error("Error", error));
  };

  render() {
    return (
      <div className="row vertical-center">
        <div className="col-6" />
        <div className="col">
          <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
            <CardBody>
              <h3>Welcome to Qtracks</h3>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="username">Username:</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter your username"
                    value={this.props.username}
                    onChange={this.changeUsername}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password:</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={this.props.password}
                    onChange={this.changePassword}
                  />
                </FormGroup>
                <Button>Sign in</Button>
                <span> or </span>
                <a
                  className="btn btn-secondary"
                  href="https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=https%3A%2F%2Fbidasktrader.com%2Fregister&client_id=A12B312C4336%40AMER.OAUTHAP"
                >
                  Sign up to Qtracks
                </a>
              </Form>
            </CardBody>
            {/* <CardFooter className="text-muted">
                            Support: (323) 472-3400
                          </CardFooter> */}
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboardReducer }) => dashboardReducer;

export default connect(
  mapStateToProps,
  dashboardActions
)(Login);
