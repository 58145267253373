import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dashboard from "./Dashboard";
import UserOrders from "./UserOrders";
import UserPosition from "./UserPosition";
import Register from "./Register";
import NotFound from "./NotFound";

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/placed" component={UserOrders} />
      <Route exact path="/position" component={UserPosition} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
