import React, { Component } from "react";
import {
  Button,
  Spinner,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  CardText
} from "reactstrap";

class Position extends Component {
  state = {
    positions: [],
    currentBalances: [],
    isLoading: false
  };
  componentDidMount() {
    this.loadPosition();
    this.setState({ isLoading: true });
  }
  loadPosition = async () => {
    const localStorageRef = localStorage.getItem("access_token");
    try {
      let request = await fetch(`${process.env.REACT_APP_API_URL}/position/`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorageRef,
          "Content-Type": "application/json"
        }
      });
      let response = await request.json();
      let position = JSON.parse(response);
      console.log(position);
      this.setState({
        positions: position.securitiesAccount.positions
      });
      this.setState({
        currentBalances: position.securitiesAccount.currentBalances
      });
      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error.message);
    }
  };

  currentPositions = () => {
    return Object.keys(this.state.positions).map(index => (
      <Card className="position" key={`position${index}`}>
        <CardBody>
          <CardTitle>
            <b>
              {this.state.positions[index].instrument.assetType === "EQUITY"
                ? this.state.positions[index].instrument.symbol
                : this.state.positions[index].instrument.description}{" "}
              ({this.state.positions[index].instrument.symbol})
            </b>
          </CardTitle>
          <CardSubtitle>
            {this.state.positions[index].instrument.assetType}
          </CardSubtitle>
          <div className="currentPosition">
            <div className="averagePrice">
              Average Price: {this.state.positions[index].averagePrice}
            </div>
            <div className="currentDayProfitLoss">
              Current Day Profit Loss:
              {this.state.positions[index].currentDayProfitLoss}
            </div>
            <div className="currentDayProfitLossPercentage">
              current Day Profit Loss Percentage:
              {this.state.positions[index].currentDayProfitLossPercentage}
            </div>
            <div className="longQuantity">
              Long Quantity: {this.state.positions[index].longQuantity}
            </div>
            <div className="marketValue">
              {" "}
              Market Value: {this.state.positions[index].marketValue}
            </div>
            <div className="settledLongQuantity">
              {" "}
              Settled Long Quantity:{" "}
              {this.state.positions[index].settledLongQuantity}
            </div>
            <div className="settledShortQuantity">
              {" "}
              Settled Short Quantity:{" "}
              {this.state.positions[index].settledShortQuantity}
            </div>
            <div className="shortQuantity">
              Short Quantity: {this.state.positions[index].shortQuantity}
            </div>
          </div>
        </CardBody>
      </Card>
    ));
  };

  render() {
    return (
      <div className="Position">
        {this.state.isLoading ? (
          <Spinner
            style={{
              width: "5rem",
              height: "5rem",
              marginTop: "50px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block"
            }}
            color="primary"
          />
        ) : (
          <div className="position-container mt-5">
            <h2>Account details: </h2>
            {this.state.currentBalances ? (
              <div className="account-details row mt-2">
                <h4 className="equity col-12">General</h4>
                <div className="equity col-12">
                  Equity: ${this.state.currentBalances.equity}
                </div>
                <div className="buyingPower col-12">
                  Buying Power: ${this.state.currentBalances.buyingPower}
                </div>
                <h4 className="col-6">Market</h4>
                <h4 className="col-6">Option Market</h4>
                <div className="longMarketValue col-6">
                  Long Market Value: $
                  {this.state.currentBalances.longMarketValue}
                </div>
                <div className="longOptionMarketValue col-6">
                  Long Option Market Value: $
                  {this.state.currentBalances.longOptionMarketValue}
                </div>
                <div className="shortMarketValue col-6">
                  Short Market Value: $
                  {this.state.currentBalances.shortMarketValue}
                </div>
                <div className="shortOptionMarketValue col-6">
                  Short Option Market Value: $
                  {this.state.currentBalances.shortOptionMarketValue}
                </div>
              </div>
            ) : (
              ""
            )}
            <h2 className="mt-5">Positions: </h2>
            {this.state.positions ? this.currentPositions() : ""}
          </div>
        )}
      </div>
    );
  }
}

export default Position;
