import {
  CHANGE_WORKING_ALERT,
  CHANGE_SUCCESS_ALERT,
  CHANGE_MESSAGE_ALERT,
  CLEAN_ALERTS
} from "../types/alertsTypes";

export const toggleWorking = (value, message) => dispatch => {
  dispatch({
    type: CHANGE_WORKING_ALERT,
    payload: value
  });
  dispatch({
    type: CHANGE_MESSAGE_ALERT,
    payload: message
  });
};

export const toggleSuccess = (value, message) => dispatch => {
  dispatch({
    type: CHANGE_SUCCESS_ALERT,
    payload: value
  });
  dispatch({
    type: CHANGE_MESSAGE_ALERT,
    payload: message
  });
};

export const cleanAlerts = () => dispatch => {
  dispatch({
    type: CLEAN_ALERTS
  });
};
