import {
  CHANGE_IV_ADJUSTMENT,
  CHANGE_PRICE_ADJUSTMENT,
  CHANGE_TIME_TO_EXPIRY,
  CHANGE_CUSTOM_STRATEGY,
  CHANGE_DELTA_ORDER_TYPE,
  CHANGE_IS_NOT_VALID,
  CHANGE_DELETE,
  CHANGE_ORDERS,
  CHANGE_ALL_SELECTED,
  CHANGE_PRICING_MODEL,
  ERROR,
  LOADING
} from "../types/ordersTypes";

const INITIAL_STATE = {
  orders: [],
  toDelete: [],
  allSelected: false,
  ivAdjustment: "0",
  priceAdjustment: "0",
  pricingModel: "CONSERVATIVE",
  customStrategy: "NORMAL",
  deltaOrderType: "MARKET",
  timeToExpiryModifier: true,
  isNotValid: false,
  error: "",
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_IV_ADJUSTMENT:
      return { ...state, ivAdjustment: action.payload };
    case CHANGE_PRICE_ADJUSTMENT:
      return { ...state, priceAdjustment: action.payload };
    case CHANGE_TIME_TO_EXPIRY:
      return { ...state, timeToExpiryModifier: action.payload };
    case CHANGE_CUSTOM_STRATEGY:
      return { ...state, customStrategy: action.payload };
    case CHANGE_PRICING_MODEL:
      return { ...state, pricingModel: action.payload };
    case CHANGE_DELTA_ORDER_TYPE:
      return { ...state, deltaOrderType: action.payload };
    case CHANGE_DELETE:
      return { ...state, toDelete: action.payload, allSelected: false };
    case CHANGE_ORDERS:
      return { ...state, orders: action.payload };
    case CHANGE_IS_NOT_VALID:
      return { ...state, isNotValid: action.payload };
    case CHANGE_ALL_SELECTED:
      return { ...state, allSelected: action.payload };
    case ERROR:
      return { ...state, error: action.payload, loading: false };
    case LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
};
