import {
  CHANGE_WORKING_ALERT,
  CHANGE_SUCCESS_ALERT,
  CHANGE_ERROR_ALERT,
  CHANGE_MESSAGE_ALERT,
  CLEAN_ALERTS
} from "../types/alertsTypes";

const INITIAL_STATE = {
  working: false,
  success: false,
  errorAlert: false,
  message: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_WORKING_ALERT:
      return {
        ...state,
        working: action.payload,
        success: false,
        errorAlert: false
      };
    case CHANGE_SUCCESS_ALERT:
      return {
        ...state,
        success: action.payload,
        working: false,
        errorAlert: false
      };
    case CHANGE_ERROR_ALERT:
      return {
        ...state,
        errorAlert: action.payload,
        success: false,
        working: false
      };
    case CLEAN_ALERTS:
      return {
        ...state,
        errorAlert: false,
        success: false,
        working: false
      };
    case CHANGE_MESSAGE_ALERT:
      return { ...state, message: action.payload };
    default:
      return state;
  }
};
