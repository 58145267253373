import { combineReducers } from "redux";
import dashboardReducer from "./dashboardReducer";
import ordersReducer from "./ordersReducer";
import legsReducer from "./legsReducer";
import alertsReducer from "./alertsReducer";
import userOrdersReducer from "./userOrdersReducer";

export default combineReducers({
  dashboardReducer,
  ordersReducer,
  legsReducer,
  alertsReducer,
  userOrdersReducer
});
