import {
  CHANGE_IV_ADJUSTMENT,
  CHANGE_PRICE_ADJUSTMENT,
  CHANGE_TIME_TO_EXPIRY,
  CHANGE_IS_NOT_VALID,
  CHANGE_ORDERS,
  CHANGE_DELETE,
  CHANGE_ALL_SELECTED,
  ERROR,
  CHANGE_CUSTOM_STRATEGY,
  CHANGE_DELTA_ORDER_TYPE,
  CHANGE_PRICING_MODEL
} from "../types/ordersTypes";

import {
  CHANGE_WORKING_ALERT,
  CHANGE_SUCCESS_ALERT,
  CHANGE_ERROR_ALERT,
  CHANGE_MESSAGE_ALERT,
  CLEAN_ALERTS
} from "../types/alertsTypes";

import moment from "moment";

const formatOrder = order => {
  let formattedOrder = {
    accountId: order["accountId"],
    session: order["session"],
    duration: order["duration"],
    orderType: order["orderType"],
    legs: order["legs"].map(leg => {
      let formattedLeg = {
        orderLegType: leg["orderLegType"],
        assetType: leg["assetType"],
        symbol: leg["symbol"],
        instruction: leg["instruction"],
        quantity: leg["quantity"]
      };
      return formattedLeg;
    }),
    orderStrategyType: order["orderStrategyType"],
    price: order["price"].toString()
  };
  return formattedOrder;
};

export const updateOrders = orders => dispatch => {
  dispatch({
    type: CHANGE_ORDERS,
    payload: orders
  });
};
export const changeIvAdjustment = ivAdjustment => dispatch => {
  dispatch({
    type: CHANGE_IV_ADJUSTMENT,
    payload: ivAdjustment
  });
};

export const changePricingModel = pricingModel => dispatch => {
  dispatch({
    type: CHANGE_PRICING_MODEL,
    payload: pricingModel
  });
};

export const changePriceAdjustment = priceAdjustment => dispatch => {
  dispatch({
    type: CHANGE_PRICE_ADJUSTMENT,
    payload: priceAdjustment
  });
};

export const changeTimeToExpiry = timeToExpiry => dispatch => {
  dispatch({
    type: CHANGE_TIME_TO_EXPIRY,
    payload: timeToExpiry
  });
};

export const changeCustomStrategy = customStrategy => dispatch => {
  dispatch({
    type: CHANGE_CUSTOM_STRATEGY,
    payload: customStrategy
  });
};

export const changeDeltaOrderType = deltaOrderType => dispatch => {
  dispatch({
    type: CHANGE_DELTA_ORDER_TYPE,
    payload: deltaOrderType
  });
};

export const changeIsNotValid = isNotValid => dispatch => {
  dispatch({
    type: CHANGE_IS_NOT_VALID,
    payload: isNotValid
  });
};

export const changeAllSelected = selected => dispatch => {
  dispatch({
    type: CHANGE_ALL_SELECTED,
    payload: selected
  });
};

export const sendOrder = detailed => async (dispatch, getState) => {
  dispatch({
    type: CHANGE_MESSAGE_ALERT,
    payload: "Sending order..."
  });
  dispatch({
    type: CHANGE_WORKING_ALERT,
    payload: true
  });
  const token = localStorage.getItem("access_token");
  const account = localStorage.getItem("account_number");
  const { legs } = getState().legsReducer;
  const {
    ivAdjustment,
    priceAdjustment,
    customStrategy,
    pricingModel,
    deltaOrderType
  } = getState().ordersReducer;
  let error = "";

  const symbol = legIndex => {
    if (!detailed) {
      if (!legs[legIndex].symbol) error = "Please fill all the fields.";
      dispatch({
        type: ERROR,
        payload: "Please fill all the fields."
      });
      return legs[legIndex].symbol;
    }

    if (
      !legs[legIndex].ticker ||
      !legs[legIndex].expiration ||
      !legs[legIndex].putcall ||
      !legs[legIndex].strike
    )
      error = "Please fill all the fields.";

    return (
      legs[legIndex].ticker +
      "_" +
      moment(legs[legIndex].expiration).format("MMDDYY") +
      legs[legIndex].putcall[0] +
      legs[legIndex].strike
    );
  };

  const orderLegs = legs.map((leg, index) => {
    return {
      orderLegType: leg.orderType,
      assetType: leg.orderType,
      symbol: symbol(index).toUpperCase(),
      instruction: leg.instruction,
      quantity: leg.quantity
    };
  });

  const deltaNeutralOrder = () => {
    if (customStrategy !== "DELTA NEUTRAL") return [];
    return [
      {
        orderType: deltaOrderType.split(" ").join("_"),
        session: "NORMAL",
        duration: "DAY",
        orderStrategyType: "SINGLE",
        orderLegCollection: [
          {
            instrument: {
              symbol: orderLegs[0].symbol.split("_")[0],
              assetType: "EQUITY"
            }
          }
        ]
      }
    ];
  };

  const order = {
    accountId: account,
    orderType: "LIMIT",
    session: "NORMAL",
    duration: "DAY",
    orderStrategyType: "SINGLE",
    pricingModel,
    customStrategy: customStrategy.split(" ").join("_"),
    legs: orderLegs,
    childOrderStrategies: deltaNeutralOrder(),
    price: "Calculating...",
    ivAdjustment: ivAdjustment,
    priceAdjustment: priceAdjustment,
    timeToExpiryModifier: "false",
    status: "STAGING"
  };
  console.log(order);
  if (!error) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addorder/`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(order)
        }
      );

      const preData = await response.json();
      const data = JSON.parse(preData);
      console.log(data);
      if (data.error) {
        console.log(data.error);
        dispatch({
          type: CHANGE_ERROR_ALERT,
          payload: true
        });
        dispatch({
          type: CHANGE_MESSAGE_ALERT,
          payload: data.error
        });
      } else {
        dispatch({
          type: CHANGE_MESSAGE_ALERT,
          payload: "Order sent successfully"
        });
        dispatch({
          type: CHANGE_SUCCESS_ALERT,
          payload: true
        });
      }
    } catch (error) {
      dispatch({
        type: CHANGE_ERROR_ALERT,
        payload: true
      });
      dispatch({
        type: CHANGE_MESSAGE_ALERT,
        payload: "Error sending the order with the current params"
      });
    }
  } else {
    console.log(error);
    dispatch({
      type: CHANGE_ERROR_ALERT,
      payload: true
    });
    dispatch({
      type: CHANGE_MESSAGE_ALERT,
      payload: error
    });
    dispatch({
      type: ERROR,
      payload: error
    });
  }
  window.setTimeout(() => {
    dispatch({
      type: CLEAN_ALERTS
    });
  }, 2000);
};

export const cleanToDelete = () => dispatch => {
  dispatch({
    type: CHANGE_DELETE,
    payload: []
  });
};

export const selectOrders = indexes => dispatch => {
  dispatch({
    type: CHANGE_DELETE,
    payload: indexes
  });
};

export const deleteOrders = () => async (dispatch, getState) => {
  // send a request to delete an order
  const { orders, toDelete } = getState().ordersReducer;
  const token = localStorage.getItem("access_token");
  toDelete.map(async index => {
    let order = formatOrder(orders[index]);
    delete order.orderType;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/deleteorder/`,
      {
        method: "delete",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(order)
      }
    );
    const data = await response.json();
    console.log(data, index, order);
  });
  dispatch({
    type: CHANGE_DELETE,
    payload: []
  });
};
